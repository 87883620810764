
/* X-Small */
@media (max-width: 576px) {
    .responsive-font {
        font-size: 2vw;
    }

    .responsive-h1 {
        font-size: 6vw;
    }

    .responsive-h2 {
        font-size: 5.3vw;
    }

    .responsive-h3 {
        font-size: 4.7vw;
    }

    .responsive-h4 {
        font-size: 4vw;
    }

    .responsive-h5 {
        font-size: 3.3vw;
    }

    .responsive-h6 {
        font-size: 2.5vw;
    }

    .projects-list-max-width {
        max-width: 100%;
    }

    .card-max-size {
        max-height: 100%;
        max-width: 100%;
    }
}

/* Small */
@media (min-width: 576px) {
    .responsive-font {
        font-size: 2vw;
    }
    .responsive-h1 {
        font-size: 6vw;
    }
    .responsive-h2 {
        font-size: 5.3vw;
    }

    .responsive-h3 {
        font-size: 4.7vw;
    }

    .responsive-h4 {
        font-size: 4vw;
    }

    .responsive-h5 {
        font-size: 3.3vw;
    }

    .responsive-h6 {
        font-size: 2.5vw;
    }
    .projects-list-max-width {
        max-width: 100%;
    }
    .card-max-size {
        max-height: 100%;
        max-width: 100%;
    }
}

/* Medium */
@media (min-width: 768px) {
    .responsive-font {
        font-size: 1.3vw;
    }
    .responsive-h1 {
        font-size: 3.9vw;
    }
    .responsive-h2 {
        font-size: 3.47vw;
    }

    .responsive-h3 {
        font-size: 3vw;
    }

    .responsive-h4 {
        font-size: 2.6vw;
    }

    .responsive-h5 {
        font-size: 2.2vw;
    }

    .responsive-h6 {
        font-size: 1.7vw;
    }
    .projects-list-max-width {
        max-width: 100%;
    }
    .card-max-size {
        max-height: 100%;
        max-width: 100%;
    }
}

/* Large */
@media (min-width: 992px) {
    .responsive-font {
        font-size: 1.5vw;
    }
    .responsive-h1 {
        font-size: 4.5vw;
    }
    .responsive-h2 {
        font-size: 4vw;
    }

    .responsive-h3 {
        font-size: 3.5vw;
    }

    .responsive-h4 {
        font-size: 3vw;
    }

    .responsive-h5 {
        font-size: 2.5vw;
    }

    .responsive-h6 {
        font-size: 2vw;
    }
    .projects-list-max-width {
        max-width: 100%;
    }
    .card-max-size {
        max-height: 100%;
        max-width: 100%;
    }
}

/* Extra large */
@media (min-width: 1200px) {
    .responsive-font {
        font-size: 0.75vw;
    }
    .responsive-h1 {
        font-size: 2.25vw;
    }
    .responsive-h2 {
        font-size: 2vw;
    }

    .responsive-h3 {
        font-size: 1.8vw;
    }

    .responsive-h4 {
        font-size: 1.5vw;
    }

    .responsive-h5 {
        font-size: 1.3vw;
    }

    .responsive-h6 {
        font-size: 1vw;
    }

    .projects-list-max-width {
        max-width: 100%;
    }

    .card-max-size {
        max-height: 300vw;
        max-width: 300vw;
    }
}

/* Extra extra largee */
@media (min-width: 1400px) {
    .responsive-font {
        font-size: 0.7vw;
    }
    .responsive-h1 {
        font-size: 1.7vw;
    }
    .responsive-h2 {
        font-size: 1.5vw;
    }

    .responsive-h3 {
        font-size: 1.4vw;
    }

    .responsive-h4 {
        font-size: 1.2vw;
    }

    .responsive-h5 {
        font-size: 1vw;
    }

    .responsive-h6 {
        font-size: 0.8vw;
    }

    .projects-list-max-width {
        max-width: 65%;
    }

    .card-max-size {
        max-height: 300vw;
        max-width: 300vw;
    }
}

.card-dark {
    opacity: 0.4;
}

.card-title-button {
    color: white;
    background-color: transparent !important;
    border-width: 0px !important;
    text-align: left !important;
    padding: 0;
}

.position-right-bottom {
    position: absolute;
    bottom: 15px;
    right: 30px;
}

.download-button:hover {
    background-color: transparent !important;
    color: lightgray !important;
}

.project-card:hover {
    cursor: pointer;
    opacity: 0.9;
}
