/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.login-input {
    max-width: 500px;
}

.login-form {
    max-width: 600px;
}

.invisible-chart {
    visibility: hidden;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.save-product-button {
    position: absolute;
    top: 100px;
    right: 100px;
    max-width: 500px;
}

.tooltip{
    position:fixed !important;
}