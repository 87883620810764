.tyrens-logo {
    width: 170px;
    height: 37px;
}

.top-bar-default-size {
    height: 74px;
}

.selector {
    background-color: transparent;
    max-width: 200px;
}

.selector:focus {
    border-color: black;
    box-shadow: none;
}

.flag-icon {
    width: 30px;
    height: 20px;
    margin: 2px;
}

.ruler {
    margin: 0px;
}

.nav-link:hover .nav-link-text:focus,
.nav-link:hover .nav-link-text:hover {
    color: gray !important;
}

.text-8px {
    font-size: 8px;
}

.text-12px {
    font-size: 12px;
}

.account:hover {
    background-image: none !important;
    background-color: transparent !important;
    color: gray !important;
}



